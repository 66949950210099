@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
h1 {
  font-weight: 300;
  color: rgba(47, 62, 104, 0.65);
  font-family: Roboto;
  font-size: 1.3rem;
}

.login_page {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  z-index: 20;
  background-image: url("../../img/login_screen_bg@2x.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.login_page .content_box {
  position: relative;
  left: 0vw;
  top: 45%;
  padding: 30px 40px !important;
  transform: translateY(-60%);
  background: rgba(250, 250, 250, 0.2) !important;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
}
.login_page .content_box .login_form {
  position: relative;
  width: 80%;
  left: 10%;
}
.login_page .content_box .login_form .input {
  background: transparent !important;
  border-radius: 200px;
}
.login_page .content_box .login_form .form-control {
  padding-bottom: 8px;
}
.login_page .content_box .login_form .login_label_small {
  font-size: 0.8rem;
  color: rgba(47, 62, 104, 0.4);
  text-align: center;
  cursor: pointer;
}
.login_page .gradient_text {
  font-size: 3rem;
  background: -webkit-linear-gradient(-70deg, #CBCAA5, #334D50);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 40px 0 6px 20px rgba(51, 70, 68, 0.17);
  text-align: center;
  font-weight: 300;
  font-family: Raleway;
}
.login_page .login_header_h1 {
  position: relative;
  font-size: 3rem;
  font-weight: 300;
  font-family: Raleway;
  text-align: center;
  top: 16%;
}
.login_page .login_logo {
  width: 350px;
  height: 160px;
  position: absolute;
  top: 0px;
  left: 35px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 50%;
  background-color: transparent;
  background-image: url("../../img/new_logo_fti_rs.svg");
}

.login_btn {
  text-align: center;
  position: relative;
  padding: 4px 66px !important;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  margin-bottom: 15px;
}

.copyright {
  color: #646464;
  position: absolute;
  bottom: 10px;
  float: right;
  right: 40px;
  opacity: 1;
  z-index: 20;
  font-family: Raleway, sans-serif;
  font-weight: 300;
  font-size: 1.3rem;
  padding: 5px;
}

@media only screen and (max-width: 1400px) {
  .login_page .login_header_h1 {
    font-size: 2.2rem;
  }
  .login_page .gradient_text {
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 440px) {
  .login_page {
    background-position: top left;
  }
  .login_page .gradient_text {
    font-size: 2rem;
  }
  .login_page .login_header_h1 {
    font-size: 2rem;
    top: 22%;
  }
  .login_page .login_logo {
    width: 90%;
    height: 80px;
    position: absolute;
    top: 10px;
    left: 35px;
  }
  .login_page .content_box {
    position: relative;
    left: 0vw;
    top: 45%;
    padding: 30px 40px !important;
    transform: translateY(-60%);
  }
  .login_page .content_box .login_form {
    position: relative;
    width: 80%;
    left: 10%;
  }
  .content_box.cb_login_form {
    max-width: 90vw;
    width: 90%;
    min-height: 60px;
  }
  .copyright {
    font-size: 0.9rem;
  }
}