@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.faq_box_container {
  margin-top: 40px;
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: auto;
  grid-row-gap: 36px;
  margin-bottom: 50px;
}
.faq_box_container .faq_item {
  position: relative;
  left: 2%;
  width: 96%;
  height: auto;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  background-color: #FFF;
  border-radius: 6px;
  padding: 20px 35px;
}
.faq_box_container .faq_item p {
  position: relative;
  line-height: 2rem;
  padding-left: 20px;
}
.faq_box_container .faq_item .faq_item_content {
  display: block;
  font-weight: 300;
  color: #2F3E68;
  font-size: 0.9rem;
  line-height: 1.5rem;
}
.faq_box_container .faq_item .faq_item_name {
  transition: all 0.3s;
  position: relative;
  line-height: 38px;
  font-size: 1.2rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 600;
  margin-bottom: 10px;
  padding-top: 10px;
  left: 0px;
  margin-left: 20px;
}