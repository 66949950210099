@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.portfolio_overiview .cb_label_right {
  position: absolute;
  top: 15px;
  right: 20px;
}
.portfolio_overiview .portfolio_overiview_conainer {
  padding-left: 30px;
  padding-right: 5px;
  position: relative;
  width: 100%;
  height: 100%;
}
.portfolio_overiview .acount_balance {
  position: relative;
  font-family: "Roboto Mono", monospace;
  font-size: 2.6rem;
  color: #2F3E68;
  margin-bottom: 15px;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 10px;
}
.portfolio_overiview .po_grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 45%;
  margin-bottom: -60px;
}
.portfolio_overiview .po_grid .prev_chart {
  position: relative;
  height: 100%;
  width: 95%;
  left: 5%;
  top: -20px;
}
.portfolio_overiview .po_grid .circular_progressbar_box {
  position: relative;
  width: auto;
}
.portfolio_overiview .po_grid .circular_progressbar_box .circular_progressbar {
  width: 80px;
  height: 80px;
  font-family: Roboto;
  font-weight: 200;
  display: inline-block;
}
.portfolio_overiview .po_grid .circular_progressbar_box .textbox {
  position: relative;
  display: inline-block;
  height: auto;
  left: 10px;
  top: -22%;
}
.portfolio_overiview .po_grid .circular_progressbar_box .textbox p {
  line-height: 1.2rem;
  font-size: 0.8rem;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #2F3E68;
}
.portfolio_overiview .po_grid .circular_progressbar_box .textbox .amount {
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  line-height: 1.2rem;
  font-size: 1rem;
}
.portfolio_overiview .po_grid .circular_progressbar_box .textbox .amount_label {
  font-family: Roboto;
  font-weight: 300;
}
.portfolio_overiview .transaction_history_previw_wrapper {
  position: relative;
  width: calc(100% - 180px);
  border: 1px solid  #ccc;
  height: auto;
  margin-top: 6%;
}
.portfolio_overiview .transaction_history_previw_wrapper .thp_line {
  display: grid;
  width: 100%;
  border: 1px solid  #ccc;
  grid-template-columns: 1fr 1.3fr 0.6fr 0.9fr;
  height: 25px;
  line-height: 25px;
  vertical-align: canter;
}
.portfolio_overiview .transaction_history_previw_wrapper .thp_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: 0.8rem;
  color: #2F3E68;
}
.portfolio_overiview .transaction_history_previw_wrapper .thp_line p.currency {
  font-size: 0.8rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: "Roboto Mono", monospace;
  letter-spacing: 0px;
}
.portfolio_overiview .transaction_history_previw_wrapper .thp_line p.move_up {
  color: #25C873;
}
.portfolio_overiview .transaction_history_previw_wrapper .thp_line p.move_down {
  color: #FF7791;
}
.portfolio_overiview .transaction_history_previw_wrapper .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
}
.portfolio_overiview .transaction_history_previw_wrapper .line_legend p {
  font-family: "Roboto Mono";
  font-weight: 600;
  font-size: 0.8rem;
  color: rgba(47, 62, 104, 0.65);
}
.portfolio_overiview .withdraw_account_btn {
  position: absolute;
  bottom: -20px;
  right: 10px;
}
.portfolio_overiview .red {
  color: #FF7791;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

@media only screen and (max-width: 1400px) {
  .portfolio_overiview .portfolio_overiview_conainer {
    padding-left: 20px;
  }
  .portfolio_overiview .po_grid {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 45%;
    margin-bottom: -60px;
  }
  .portfolio_overiview .po_grid .prev_chart {
    position: relative;
    height: 100%;
    width: 98%;
    left: 2%;
    top: -20px;
  }
  .portfolio_overiview .po_grid .circular_progressbar_box {
    position: relative;
    width: auto;
  }
  .portfolio_overiview .po_grid .circular_progressbar_box .circular_progressbar {
    width: 60px;
    height: 60px;
    font-family: Roboto;
    font-weight: 300;
    display: inline-block;
    font-size: 0.8rem;
  }
  .portfolio_overiview .po_grid .circular_progressbar_box .textbox {
    position: relative;
    display: inline-block;
    height: auto;
    left: 10px;
    top: -22%;
  }
  .portfolio_overiview .po_grid .circular_progressbar_box .textbox p {
    line-height: 1.2rem;
    font-size: 0.8rem;
    position: relative;
    margin: 0px;
    padding: 0px;
    color: #2F3E68;
  }
  .portfolio_overiview .po_grid .circular_progressbar_box .textbox .amount {
    font-family: "Roboto Mono", monospace;
    font-weight: 600;
    line-height: 1.1rem;
    font-size: 1rem;
    letter-spacing: 0px;
  }
  .portfolio_overiview .po_grid .circular_progressbar_box .textbox .amount_label {
    font-family: Roboto;
    font-weight: 300;
  }
  .portfolio_overiview .transaction_history_previw_wrapper {
    position: relative;
    width: calc(100% - 160px);
    border: 1px solid  #ccc;
    height: auto;
    margin-top: 6%;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .thp_line {
    display: grid;
    width: 100%;
    border: 1px solid  #ccc;
    grid-template-columns: 1fr 1.3fr 0.6fr 0.9fr;
    height: 25px;
    line-height: 25px;
    vertical-align: canter;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .thp_line p {
    font-family: Roboto;
    font-weight: 300;
    font-size: 0.7rem;
    color: #2F3E68;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .thp_line p.currency {
    font-size: 0.7rem;
    color: rgba(47, 62, 104, 0.65);
    font-weight: 500;
    font-family: "Roboto Mono", monospace;
    letter-spacing: 0px;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .thp_line p.move_up {
    color: #25C873;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .thp_line p.move_down {
    color: #FF7791;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .line_legend {
    border-bottom: 1px solid #2F3E68;
    height: 25px;
    margin-bottom: 5px;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .line_legend p {
    font-family: "Roboto Mono";
    font-weight: 600;
    font-size: 0.7rem;
    color: rgba(47, 62, 104, 0.65);
  }
  .portfolio_overiview .withdraw_account_btn {
    position: absolute;
    bottom: -20px;
    right: 10px;
    width: 30%;
  }
}