@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.identity_documents_wrapper .identity_documents_container .user_ident_line {
  position: relative;
  height: auto;
  background-color: #FFFFFF;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  line-height: 1.1rem;
  margin-bottom: 25px;
  border-radius: 6px;
  padding: 14px 25px;
  width: 90%;
  left: 5%;
  cursor: pointer;
  transition: all 0.2s;
}
.identity_documents_wrapper .identity_documents_container .user_ident_line.edit:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 40px rgba(69, 92, 90, 0.28);
}
.identity_documents_wrapper .identity_documents_container .user_ident_line p {
  font-size: 0.85rem;
  color: #2F3E68;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: left;
  font-family: Raleway, sans-serif;
}
.identity_documents_wrapper .identity_documents_container .user_ident_line .status_icon {
  position: absolute;
  right: 14px;
  height: 21px;
  width: 21px;
  border-radius: 40px;
  top: 50%;
  transform: translateY(-50%);
  background-size: 62%;
  background-position: center;
  background-repeat: no-repeat;
}
.identity_documents_wrapper .identity_documents_container .user_ident_line.valid .status_icon {
  background-color: #25C873;
  box-shadow: 0px 0px 14px rgba(37, 200, 115, 0.7);
  background-image: url(../../img/icons/tick_icon.svg);
  background-size: 90%;
  background-position: 120% 30%;
}
.identity_documents_wrapper .identity_documents_container .user_ident_line.invalid .status_icon {
  background-color: #FF7791;
  box-shadow: 0px 0px 14px rgba(255, 119, 145, 0.7);
  background-image: url(../../img/icons/cros_icon.svg);
}
.identity_documents_wrapper .identity_documents_container .identity_documents_edit {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-top: -10px;
  margin-bottom: -30px;
}
.identity_documents_wrapper .identity_documents_container .identity_documents_edit .id_preview {
  position: relative;
  width: 95%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  margin-top: -5px;
}
.identity_documents_wrapper .identity_documents_container .identity_documents_edit .empty_img {
  position: relative;
  width: 100%;
  height: 95px;
  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  background-color: #FFFFFF;
  background-image: url("../../img/icons/image_icon.svg");
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
}
.identity_documents_wrapper .identity_documents_container .identity_documents_edit .upload_form_vertical {
  margin-top: 20px;
}
.identity_documents_wrapper .identity_documents_container .close_bnt {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.identity_documents_wrapper .msg {
  position: absolute;
  bottom: -15px;
  left: 0px;
  text-align: center;
  width: 100%;
  pointer-events: none;
  font-size: 0.8rem;
  border-bottom: 2px solid;
}
.identity_documents_wrapper .msg.msg--success {
  text-shadow: 0px 0px 20px rgba(37, 200, 115, 0.8);
}
.identity_documents_wrapper .msg.msg--error {
  text-shadow: 0px 0px 20px rgba(255, 119, 145, 0.8);
}