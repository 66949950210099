@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.deposit_account_container .overlay_filter_container {
  position: fixed;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
}
.deposit_account_container .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}
.deposit_account_container .withdraw_container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FFFFFF;
  padding: 60px;
  height: auto;
}
.deposit_account_container .withdraw_container .cb_label_right {
  right: 8px;
  top: 8px;
}
.deposit_account_container .withdraw_container .sec_label {
  left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  line-height: 1.3rem;
}
.deposit_account_container .withdraw_container .text_box {
  left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  max-width: 80%;
  line-height: 1.3rem;
}
.deposit_account_container .withdraw_container .text_box .sec_text {
  color: rgba(47, 62, 104, 0.4);
}
.deposit_account_container .qr_payment {
  width: 20%;
  min-width: 170px;
  opacity: 0.6;
  float: right;
  position: absolute;
  right: 20px;
  bottom: 20px;
}