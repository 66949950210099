@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.file_upload_form {
  position: relative;
  max-width: 400px;
  height: auto;
}

.file_input {
  visibility: hidden;
  display: none;
  position: absolute;
  pointer-events: none;
}

.file_upload_form_grid {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
}
.file_upload_form_grid .file_input_label {
  position: relative;
  max-width: 230px;
  width: auto;
  height: 38px;
  line-height: 38px;
  padding: 0px 25px;
  font-size: 0.85rem;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}
.file_upload_form_grid .file_input_label::after {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  background-image: url("../../img/icons/upload_icon.svg");
  background-size: 43%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2F3E68;
  box-shadow: 0px 0px 30px rgba(47, 62, 104, 0.4);
  border-radius: 100px;
  right: 25px;
  top: 8px;
}
.file_upload_form_grid .file_input_label:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 30px rgba(69, 92, 90, 0.3);
}
.file_upload_form_grid .btn {
  position: relative;
  top: 5px;
}
.file_upload_form_grid .btn.focus {
  box-shadow: 0 6px 30px rgba(69, 92, 90, 0.3);
  border-color: #00BAFF;
  color: #00BAFF;
}

.upload_form_vertical .file_upload_form_grid {
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  margin-top: -25px;
}