@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.nav_header {
  background-color: #FFF;
  padding: 0px 0px;
  width: 100%;
  text-align: center;
  height: 60px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
}

.nav_header .top_text {
  text-decoration: none;
  font-size: 1.2em;
  top: 20px;
  position: fixed;
  width: 99vw;
  text-align: center;
  left: 0px;
  color: #aaa;
  font-family: "Roboto Mono", monospace;
}

@media only screen and (max-width: 440px) {
  .nav_header .top_text {
    font-size: 1em;
    top: 14px;
    width: 72vw;
    left: 20vw;
  }
}
.logout_user {
  text-align: right;
  position: absolute;
  right: 30px;
  top: 20px;
  display: flex;
}

.top_logo {
  width: auto;
  height: 40px;
  top: 10px;
  position: fixed;
  float: left;
  left: 16px;
  cursor: pointer;
}

.nav_panel {
  position: fixed;
  background-color: #FFFFFF;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  width: 60px;
  height: 100vh;
  left: 0px;
  top: 0px;
  z-index: 100;
}

.nav_panel_logo {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  background-color: transparent;
  background-image: url("../../img/FTI_logo_recreate_xs@2x.svg");
  transition: box-shadow 0.3s;
}
.nav_panel_logo:hover {
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.08);
}

.nav_panel_links {
  position: absolute;
  display: table;
  left: 0px;
  width: 60px;
  height: 90vh;
  top: 80px;
}

.nav_panel_link {
  width: 60px;
  margin-left: 0px;
  height: 60px;
  margin-bottom: 14px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 26px 26px;
  cursor: pointer;
  background-color: transparent;
  opacity: 0.9;
  border-left-width: 0px;
  transition: all 0.3s;
  filter: grayscale(100%) brightness(120%);
}
.nav_panel_link:hover {
  filter: grayscale(0%);
  border-right-width: 4px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.08);
  border-style: solid;
  -o-border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
     border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
}

@media screen and (max-width: 440px) {
  .nav_header {
    height: 50px;
  }
  .nav_panel_link {
    width: 50px;
    height: 50px;
  }
  .nav_panel {
    width: 50px;
  }
  .nav_panel_logo {
    width: 50px;
    height: 50px;
  }
  .logout_user {
    right: 30px;
    top: 12px;
    opacity: 0.8;
  }
}
.link_0 {
  background-image: url("../../img/icons/overview_icon.svg");
}

.link_1 {
  background-image: url("../../img/icons/docs_icon.svg");
}

.link_2 {
  background-image: url("../../img/icons/faq_icon.svg");
}

.link_3 {
  background-image: url("../../img/icons/news_icon.svg");
}

.link_4 {
  background-image: url("../../img/icons/users_icon.svg");
}

.link_5 {
  background-image: url("../../img/icons/settings_icon.svg");
}

.link_6_logout {
  background-image: url("../../img/icons/logout_icon.svg");
  position: absolute;
  bottom: 10px;
}

.active {
  background-color: #ffffff;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.06);
  filter: grayscale(0%);
  border-right-width: 4px;
  border-style: solid;
  -o-border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
     border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
}

.user_info {
  position: absolute;
  left: 100px;
  top: 10px;
  height: 40px;
  width: 300px;
  display: flex;
}
.user_info .profile_img {
  position: relative;
  width: 40px;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  border-radius: 100px;
}
.user_info p {
  font-family: Raleway, sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative;
  top: 10px;
  left: 15px;
}

.user_amount_stats_wraper {
  position: absolute;
  display: flex;
  height: 30px;
  right: calc(30px + 13vw);
  top: 13px;
}
.user_amount_stats_wraper .user_amount_stats {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 0.2fr;
  width: 20em;
  max-height: 100%;
}
.user_amount_stats_wraper .user_amount_stats .user_amount_stats_box {
  position: relative;
  display: block;
}
.user_amount_stats_wraper .user_amount_stats .user_amount_stats_box p {
  font-size: 0.9rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: "Roboto Mono", monospace;
  line-height: 1rem;
  margin-bottom: 0px;
  text-align: left;
  letter-spacing: 0px;
}
.user_amount_stats_wraper .user_amount_stats .user_amount_stats_box .label {
  font-size: 0.7rem;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  letter-spacing: 1px;
}

.notification_btn {
  position: relative;
  width: 26px;
  height: 26px;
  background-image: url("../../img/alarm.svg");
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  border-radius: 50px;
  background-color: #fff;
  transition: all 0.3s;
  cursor: pointer;
  top: 5px;
}
.notification_btn:hover {
  transform: translateY(-2px);
}
.notification_btn.new_notif::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #27DEFF;
  box-shadow: 0px 0px 10px rgba(39, 222, 255, 0.6);
  border-radius: 20px;
  top: 1px;
}

.invest_nav_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.system_notification_box {
  position: fixed;
  top: 80px;
  right: 30px;
  z-index: 1100;
}
.system_notification_box .notification {
  position: fixed;
  top: 80px;
  right: 30px;
  z-index: 1100;
}