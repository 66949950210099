@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.create_investment_container .overlay_filter_container {
  position: fixed;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
}
.create_investment_container .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}
.create_investment_container .create_investment_cb {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FFFFFF;
  padding: 60px;
  height: auto;
}
.create_investment_container .create_investment_cb .cb_label_right {
  right: 8px;
  top: 8px;
}
.create_investment_container .create_investment_cb .cb_label_left {
  position: relative;
  left: 0px;
  top: 0px;
  text-align: left;
  color: #2F3E68;
}
.create_investment_container .create_investment_cb .sec_label {
  margin-top: -20px;
  margin-bottom: 20px;
  position: relative;
  left: 0px;
  top: 0px;
  text-align: left;
  font-weight: 300;
  font-family: Roboto;
  color: rgba(47, 62, 104, 0.65);
}
.create_investment_container .create_investment_cb .user_documents {
  position: relative;
  width: 74%;
  top: 30px;
  left: 60px;
  margin-bottom: 40px;
}
.create_investment_container .create_investment_cb .investment_params {
  position: relative;
  width: 76%;
  top: 30px;
  left: 60px;
  margin-bottom: 40px;
}
.create_investment_container .create_investment_cb .investment_params .investment_params_box {
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
}
.create_investment_container .create_investment_cb .investment_params .text_edit_item.mid_size_input {
  position: relative;
  width: 92%;
  max-width: 92%;
  margin-left: 2%;
}
.create_investment_container .create_investment_cb .investment_params .text_edit_item.mid_size_input .input {
  width: 100%;
}
.create_investment_container .create_investment_cb .investment_params .text_edit_item.invest_input .input {
  border-color: #00BAFF;
}
.create_investment_container .create_investment_cb .investment_params .text_edit_item.hlaf_input {
  position: relative;
  width: 45%;
  max-width: 45%;
  display: inline-block;
  margin-left: 2%;
}
.create_investment_container .create_investment_cb .investment_params .text_edit_item.hlaf_input.sec {
  margin-left: 6%;
}
.create_investment_container .create_investment_cb .user_status {
  font-size: 1rem;
  font-weight: 300;
}
.create_investment_container .create_investment_cb .user_status .user_status_inner {
  font-weight: 300;
  color: rgba(47, 62, 104, 0.65);
}
.create_investment_container .create_investment_cb .user_status .user_status_inner.ok {
  color: #25C873;
}
.create_investment_container .create_investment_cb .user_status .user_status_inner.error {
  color: #FF7791;
}
.create_investment_container .create_investment_cb .page_switch_wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  width: auto;
  height: 34px;
  background-color: #EEEEEE;
  border-radius: 80px;
  padding-right: 6px;
}
.create_investment_container .create_investment_cb .page_switch_wrapper .page {
  position: relative;
  height: 28px;
  top: 3px;
  left: 3px;
  padding: 0px 30px;
  display: inline-block;
  border-radius: 80px;
  cursor: pointer;
}
.create_investment_container .create_investment_cb .page_switch_wrapper .page p {
  color: rgba(47, 62, 104, 0.65);
  line-height: 28px;
  vertical-align: center;
  font-size: 0.8rem;
}
.create_investment_container .create_investment_cb .page_switch_wrapper .page.active_page {
  background-color: #FFF;
  box-shadow: 0 10px 40px rgba(69, 92, 90, 0.28);
}
.create_investment_container .create_investment_cb .page_switch_wrapper .page.active_page p {
  color: #2F3E68;
}
.create_investment_container .create_investment_cb .continue_invest {
  position: absolute;
  bottom: 30px;
  right: 50px;
}
.create_investment_container .create_investment_cb .continue_invest_sign {
  position: absolute;
  bottom: -25px;
  right: -17%;
}
.create_investment_container .create_investment_cb .agreement_invest {
  position: absolute;
  bottom: -25px;
  left: 10px;
}
.create_investment_container .circular_progressbar_box {
  position: absolute;
  width: auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 260px;
  left: calc(100% - 160px);
  top: 0px;
}
.create_investment_container .circular_progressbar_box .circular_progressbar {
  width: 80px;
  height: 80px;
  font-family: Roboto;
  font-weight: 200;
  display: inline-block;
}
.create_investment_container .circular_progressbar_box .textbox {
  position: relative;
  display: inline-block;
  height: auto;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 55px;
}
.create_investment_container .circular_progressbar_box .textbox p {
  line-height: 1.2rem;
  font-size: 0.8rem;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #2F3E68;
}
.create_investment_container .circular_progressbar_box .textbox .amount {
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  line-height: 1.8rem;
  font-size: 1.6rem;
}
.create_investment_container .circular_progressbar_box .textbox .amount_label {
  font-family: Roboto;
  font-weight: 300;
}