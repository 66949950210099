@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.withdraw_account_container .overlay_filter_container {
  position: fixed;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
}
.withdraw_account_container .close_bnt {
  position: fixed;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}
.withdraw_account_container .withdraw_container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FFFFFF;
  padding: 60px;
  max-height: 280px;
}
.withdraw_account_container .withdraw_container .cb_label_right {
  right: 24px;
  top: 24px;
}
.withdraw_account_container .withdraw_container .sec_label {
  left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.withdraw_account_container .inner_container {
  position: relative;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.withdraw_account_container .circular_progressbar_box {
  position: relative;
  width: auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 50%;
  min-width: 260px;
  left: 30px;
}
.withdraw_account_container .circular_progressbar_box .circular_progressbar {
  width: 80px;
  height: 80px;
  font-family: Roboto;
  font-weight: 200;
  display: inline-block;
}
.withdraw_account_container .circular_progressbar_box .textbox {
  position: relative;
  display: inline-block;
  height: auto;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 55px;
}
.withdraw_account_container .circular_progressbar_box .textbox p {
  line-height: 1.2rem;
  font-size: 0.8rem;
  position: relative;
  margin: 0px;
  padding: 0px;
  color: #2F3E68;
}
.withdraw_account_container .circular_progressbar_box .textbox .amount {
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  line-height: 1.8rem;
  font-size: 1.6rem;
}
.withdraw_account_container .circular_progressbar_box .textbox .amount_label {
  font-family: Roboto;
  font-weight: 300;
}
.withdraw_account_container .input_container {
  position: relative;
  display: inline-block;
  width: 90%;
  left: 5%;
  top: 30px;
}
.withdraw_account_container .input_container .button {
  position: relative;
  display: inline-block;
  transform: translateY(-100%);
  top: -6px;
  left: 20%;
}
.withdraw_account_container .input_container .text_edit_item {
  display: inline-block;
}
.withdraw_account_container .input_container .text_edit_item .input-half {
  width: 100%;
  max-width: 100%;
}
.withdraw_account_container .input_container .text_edit_item .mid_input {
  width: 70%;
  max-width: 100%;
}