@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.main_page_content {
  position: relative;
  width: 100%;
  left: -10px;
  margin-top: 8vw;
}
.main_page_content .padding {
  height: 6vw;
  width: 100%;
}
.main_page_content p {
  position: relative;
  font-family: Roboto;
  font-weight: 300;
  color: rgba(47, 62, 104, 0.65);
  font-size: 1.1rem;
  letter-spacing: 2px;
  line-height: 1.8rem;
}
.main_page_content .main_page_contentbox {
  position: relative;
  width: 80%;
  left: 10%;
}
.main_page_content .gradient_text {
  font-size: 3.5rem;
  background: -webkit-linear-gradient(-70deg, #CBCAA5, #334D50);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 40px 0 6px 20px rgba(51, 70, 68, 0.17);
  text-align: center;
  font-weight: 300;
  font-family: Raleway;
}
.main_page_content .view0_header, .main_page_content .view1_header {
  text-align: center;
}
.main_page_content .view0_header .view0_header_h1, .main_page_content .view0_header .view1_header_h1, .main_page_content .view1_header .view0_header_h1, .main_page_content .view1_header .view1_header_h1 {
  font-size: 3.5rem;
  font-weight: 300;
  font-family: Raleway;
  text-align: center;
}
.main_page_content .view0_header p, .main_page_content .view1_header p {
  position: relative;
  width: 60%;
  left: 20%;
  margin-top: 30px;
}
.main_page_content .view0_item_grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 30px;
}
.main_page_content .view0_item_grid .grid_item_box p {
  width: 80%;
  left: 10%;
  position: relative;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: center;
  line-height: 1.4rem;
}
.main_page_content .view0_item_grid .grid_item_box .grid_item_img {
  position: relative;
  width: 10vw;
  left: 50%;
  height: 10vw;
  transform: translateX(-50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  filter: saturate(70%);
}
.main_page_content .view0_item_grid .grid_item_box .grid_item_img.img01 {
  background-image: url("../../img/15@2x.png");
}
.main_page_content .view0_item_grid .grid_item_box .grid_item_img.img02 {
  background-image: url("../../img/Group_1174@2x.png");
}
.main_page_content .view0_item_grid .grid_item_box .grid_item_img.img03 {
  background-image: url("../../img/Group_1249@2x.png");
}
.main_page_content .view0_item_grid .grid_item_box .grid_item_img.img04 {
  background-image: url("../../img/Group_1165@2x.png");
}
.main_page_content .view0_item_grid .grid_item_box .grid_item_img.img05 {
  background-image: url("../../img/14@2x.png");
}
.main_page_content .sys_prev_img {
  position: relative;
  width: 65vw;
  height: 42vw;
  left: 50%;
  top: 2vw;
  transform: translateX(-50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../img/Group_1330@2x.png");
}
.main_page_content .bg_waves_img_container {
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 42vw;
  left: -4%;
  top: 55vw;
  overflow: hidden;
}
.main_page_content .bg_waves_img_container .bg_waves_img {
  position: relative;
  z-index: -1;
  width: 110vw;
  height: 100%;
  left: -2%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../img/gr_waves.svg");
}
.main_page_content .homepage_logo {
  width: 310px;
  height: 120px;
  position: absolute;
  bottom: 0px;
  right: 40px;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 50%;
  background-color: transparent;
  background-image: url("../../img/main_logo.svg");
}
.main_page_content .copyright {
  color: rgba(47, 62, 104, 0.65);
  position: absolute;
  bottom: 16px;
  float: left;
  left: 40px;
  opacity: 1;
  z-index: 20;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 5px;
}