@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #2F3E68;
}

.content_box {
  position: relative;
  background: #fff !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  -webkit-box-shadow: 0 10px 40px rgba(69, 92, 90, 0.12);
          box-shadow: 0 10px 40px rgba(69, 92, 90, 0.12);
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  padding: 1rem 1.2rem !important;
}

.content_box.cb-small {
  max-width: 40vw;
  min-height: 20vw;
}

.content_box.cb-small-dialog {
  max-width: 28vw;
  min-height: 10vw;
  padding: 2rem 3rem .5rem 3rem;
}

.content_box.cb-small-short {
  max-width: 40vw;
  min-height: 60px;
}

.content_box.cb_login_form {
  max-width: 480px;
  min-height: 280px;
}

.content_box.cb-mid {
  max-width: 58vw;
  min-height: 30vw;
}

.content_box p {
  font-weight: 500;
  font-size: .9rem;
  color: rgba(47, 62, 104, 0.65);
}

/* @media only screen and (max-width: 1400px)
    .content_box
        &.component_cb
            max-height: 45vh */
@media only screen and (max-width: 440px) {
  .cb-small {
    max-width: 85vw;
    min-height: 20vw;
  }
  .cb-small-short {
    max-width: 85vw;
    min-height: 60px;
  }
  .cb_login_form {
    max-width: 80vw;
    min-height: 220px;
  }
}

.component_cb {
  position: relative;
  padding: 25px;
}

.fullpage_component_wapper {
  position: relative;
  padding: 25px;
  width: 80%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.overlay_filter_container {
  position: fixed;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
}

.component_cb_fullpage {
  position: relative;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.component_cb_fullpage .fullpage_component_content_wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.cb_user_info {
  margin: 40px 60px;
}

.components_container_overview {
  position: relative;
  width: 95%;
  left: 1.5%;
  height: calc(96vh - 60px);
  max-height: 1000px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
}

.components_container_overview .component_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: block;
}

.components_container_overview .component_wrap .content_box {
  position: relative;
  width: 100%;
  height: 100%;
}

.cb_label_left {
  position: relative;
  left: 0px;
  top: 0px;
  text-align: left;
}

.cb_label_right {
  position: relative;
  right: 0px;
  float: right;
  top: 0px;
  text-align: right;
}

.fullpage_component_headline {
  position: relative;
  right: 0px;
  float: right;
  top: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
}

.fullpage_component_headline .cb_fullpage_label_right {
  position: relative;
  float: right;
  text-align: right;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  color: rgba(47, 62, 104, 0.65);
  letter-spacing: 2px;
  font-size: 1.8rem;
}

.fullpage_component_headline .cb_fullpage_sublabel_right {
  position: relative;
  float: right;
  text-align: right;
  font-size: .8rem;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  color: rgba(47, 62, 104, 0.65);
}

.msg--success {
  color: #25C873 !important;
  border-color: #25C873;
}

.msg--error {
  color: #FF7791 !important;
  border-color: #FF7791;
}

.cms_page_container {
  position: absolute;
  left: 60px;
  top: 60px;
  width: calc(100vw - 60px);
  max-width: calc(100vw - 60px);
}

.cms_page_wraper {
  position: relative;
  left: 0px;
  width: calc(100vw - 60px);
  padding: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 440px) {
  .cms_page_wraper {
    position: relative;
    left: 0px;
    width: calc(100vw - 60px);
    padding: 20px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  .top_text {
    display: none;
  }
}

.cards_item {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin-bottom: 40px;
}

.cards_item_fixed {
  position: fixed;
  margin-left: 3.4vw;
  display: -ms-grid;
  display: grid;
  grid-row-gap: 40px;
}

@media only screen and (max-width: 440px) {
  .cards_item_fixed {
    position: relative;
    display: -ms-grid;
    display: grid;
    margin-left: 0vw;
    grid-row-gap: 40px;
  }
}

.cards_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1120px;
  width: calc(100vw - 60px);
  left: 60px;
  margin: 0 auto;
}

.cards_wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards_items {
  margin-bottom: 24px;
}

.input_label {
  font-size: .7rem;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  margin-left: 10px;
  color: rgba(47, 62, 104, 0.65);
}

.page_headline {
  position: relative;
  left: 40px;
  margin-top: 30px;
  display: table;
  color: #2F3E68;
  font-family: Roboto, sans-serif;
  font-weight: 100;
  font-size: 2rem;
  opacity: .3;
  letter-spacing: 2px;
}

.highlight_box {
  border: 0;
  -webkit-box-shadow: 0 6px 30px rgba(69, 92, 90, 0.7) !important;
          box-shadow: 0 6px 30px rgba(69, 92, 90, 0.7) !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: .4s;
  transition: .4s;
  border-left-width: 3px;
  border-style: solid;
  -o-border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
     border-image: -webkit-gradient(linear, left top, left bottom, from(#CBCAA5), to(#334D50)) 1 100%;
     border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
}

.action_box_container {
  height: 80px;
  padding-right: 0px !important;
}

.action_box_container.long {
  height: 120px;
}

.action_box_container.long .label {
  margin-top: -8px;
}

.action_box_container.fixed {
  position: fixed;
  margin-left: 3.5vw;
}

.action_box_container .action_box_wrpaper {
  width: 96%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.2fr .8fr;
      grid-template-columns: 1.2fr .8fr;
  position: relative;
  height: 100%;
}

.action_box_container .action_box_wrpaper.fr_grid {
  -ms-grid-columns: (auto)[4];
      grid-template-columns: repeat(4, auto);
}

.action_box_container .action_box_wrpaper .label {
  position: relative;
  top: 55%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: rgba(47, 62, 104, 0.65);
  display: inline-block;
  font-size: .85rem;
}

.action_box_container .action_box_wrpaper .unsaved_changes {
  position: relative;
  top: -25px;
  color: rgba(47, 62, 104, 0.6);
  display: inline-block;
  font-size: .8rem;
}

.action_box_container .action_box_wrpaper button {
  position: relative;
  display: inline-block;
  top: 38%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media only screen and (max-width: 440px) {
  .label {
    font-size: .8rem;
    margin-top: -8px;
  }
  .action_box_container {
    height: 100px;
    padding-right: 0px !important;
  }
  .action_box_container.long {
    height: 120px;
  }
  .action_box_container button {
    position: relative;
    display: inline-block;
    top: 10px;
  }
  .action_box_container .action_box_wrpaper {
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .action_box_container .action_box_wrpaper.fr_grid {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
  }
}

.img_edit_container {
  position: relative;
  grid-row-gap: 30px;
}

.img_edit_item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3fr;
      grid-template-columns: 1fr 3fr;
}

.img_edit_item .input_label {
  position: relative;
  left: 180px;
  top: -30px;
}

.img_edit_prev {
  position: absolute;
  left: 0px;
  height: 100px;
  top: 0px;
  width: 160px;
  -o-object-fit: cover;
     object-fit: cover;
}

.circular_progressbar {
  width: 86px;
  height: 86px;
}

.circular_progressbar_small {
  width: 50px;
  height: 50px;
}

.page_container {
  position: relative;
  padding-left: 80px;
  width: 100%;
  padding-top: 80px;
}

.prev_chart {
  height: 110px;
  width: 220px;
}

.inactive_textbox {
  background-color: #FFF;
  margin-right: 10px;
  padding: 0;
  position: relative;
  border-radius: 5px;
  margin-top: 5px;
  border: 0;
  color: #2F3E68;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 1.5px;
  padding: 7px 16px;
  padding-right: 50px;
  width: auto;
  min-width: 20vw;
  display: inline-block;
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-box-shadow: 0 6px 20px rgba(52, 80, 78, 0.17);
          box-shadow: 0 6px 20px rgba(52, 80, 78, 0.17);
}

.er_404 {
  position: relative;
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
  background-image: url("../img/page_bg_cubes@2x.jpg");
  background-size: cover;
  background-position: center;
}

.er_404 .content_404 {
  position: fixed;
  left: 50vw;
  top: 50vh;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 40vw;
}

.er_404 .content_404 h1 {
  color: #2F3E68;
  font-size: 12rem;
  font-family: 'Roboto Mono', monospace;
  text-align: center;
}

.er_404 .content_404 h3 {
  color: rgba(47, 62, 104, 0.65);
  text-align: center;
  font-size: 2rem;
  padding-top: 10px;
  border-top: 2px solid #27DEFF;
}

@media only screen and (min-height: 1100px) {
  .investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper {
    height: calc( 92px + 14px + 4*46px) !important;
  }
  .payment_calendar_wrapper .payment_table_wrapper {
    height: 82% !important;
  }
}

@media only screen and (max-height: 890px) {
  .investment_box_grid {
    top: 4% !important;
  }
  .components_container_overview .component_wrap .content_box p {
    margin-bottom: .1em;
  }
  .payment_calendar_wrapper .payment_table_wrapper {
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: 84% !important;
  }
}

@media only screen and (max-width: 1490px) {
  .portfolio_overiview .po_grid .circular_progressbar_box .textbox .amount {
    font-size: .8rem !important;
  }
  .investment_box_grid {
    width: 80% !important;
    left: 10% !important;
  }
  .transactions_component .main_content_box .line_legend p {
    font-size: .65rem !important;
  }
  .transactions_component .cl_header_grid .reg_button {
    width: 94% !important;
  }
  .transactions_component .cl_header_grid .reg_button_link {
    width: 94% !important;
  }
  .fullpage_component_headline .cb_fullpage_sublabel_right {
    font-size: .6rem !important;
  }
  .fullpage_component_wapper {
    width: 90%;
  }
  .content_box.cb-small {
    max-width: 70vw !important;
  }
  .content_box.cb-mid {
    max-width: 74vw !important;
  }
}

@media only screen and (max-height: 740px) {
  .components_container_overview {
    height: calc(116vh - 60px);
  }
}

@media only screen and (max-height: 660px) {
  .portfolio_overiview .po_grid .circular_progressbar_box .textbox {
    top: -12% !important;
  }
  .portfolio_overiview .transaction_history_previw_wrapper .thp_line p {
    font-size: .6rem !important;
  }
  .investment_overview_wrapper .investment_table_wrapper .inv_line_box_wrapper .inv_line_box p {
    font-size: .6rem !important;
  }
  .payment_calendar_wrapper .payment_table_wrapper .pmt_line_box p {
    font-size: .7rem !important;
  }
  .investment_box_grid .ivn_container {
    max-height: 43vh !important;
  }
  .investment_box_grid {
    width: 86% !important;
    left: 7% !important;
  }
}
