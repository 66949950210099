@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.investment_box_grid {
  position: relative;
  width: 70%;
  height: 85%;
  left: 15%;
  top: 0%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}
.investment_box_grid .ivn_container {
  padding: 25px !important;
  text-align: center;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 2fr 1fr;
  max-height: 38vh;
}
.investment_box_grid .ivn_container h3 {
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: #2F3E68;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 1.3rem;
}
.investment_box_grid .ivn_container .interest {
  position: relative;
  top: -5px;
}
.investment_box_grid .ivn_container .button {
  position: relative;
  top: 20px;
}
.investment_box_grid .ivn_container .bg_tx {
  position: absolute;
  font-size: 14rem;
  right: -10px;
  top: -10px;
  z-index: 0;
  font-weight: 500;
  color: rgba(47, 62, 104, 0.65);
  opacity: 0.04;
}

.gradient_text {
  font-size: 3rem;
  background: -webkit-linear-gradient(-70deg, #CBCAA5, #334D50);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 40px 0 6px 20px rgba(51, 70, 68, 0.17);
  font-weight: 500;
  font-family: "Roboto mono", monospace;
  text-align: center;
}

@media only screen and (max-width: 1400px) {
  .investment_box_grid {
    position: relative;
    width: 80%;
    height: 85%;
    left: 10%;
    top: 0%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
  }
  .investment_box_grid .cb_label_right {
    font-size: 0.7rem;
  }
  .investment_box_grid .ivn_container {
    padding: 15px !important;
    text-align: center;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 2fr 1fr;
    max-height: 34vh;
  }
  .investment_box_grid .ivn_container h3 {
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #2F3E68;
    margin-top: 5px;
    margin-bottom: 8px;
    font-size: 1rem;
  }
  .investment_box_grid .ivn_container .interest {
    position: relative;
    top: -5px;
  }
  .investment_box_grid .ivn_container .button {
    position: relative;
    top: 10px;
  }
  .investment_box_grid .ivn_container .bg_tx {
    position: absolute;
    font-size: 12rem;
    right: -10px;
    top: -10px;
    z-index: 0;
    font-weight: 500;
    color: rgba(47, 62, 104, 0.65);
    opacity: 0.04;
  }
  .gradient_text {
    font-size: 2rem;
  }
}