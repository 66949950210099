@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.success-animation {
  margin: 30px auto;
}
.success-animation .checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #25C873;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #25C873;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.success-animation .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #25C873;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.success-animation .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #25C873;
  }
}

.error-animation {
  margin: 30px auto;
}
.error-animation .checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #FF7791;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #FF7791;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.error-animation .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #FF7791;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.error-animation .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #FF7791;
  }
}

.sms_code_input_textbox {
  position: relative;
  width: 12rem;
  left: 50%;
  margin-top: 50px;
  transform: translateX(-50%);
}
.sms_code_input_textbox input {
  font-size: 1.4rem;
  width: 12rem;
  letter-spacing: 11px;
  text-align: center;
}

.wrong_code {
  text-align: center;
}

.sms_code_headline {
  position: relative;
  text-align: center;
  font-family: Roboto;
  font-size: 0.9rem;
  width: 80%;
  left: 10%;
  top: 20px;
  margin-bottom: -5px;
}