@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.settings_component .user_status {
  font-size: 1rem;
  font-weight: 300;
}
.settings_component .user_status .user_status_inner {
  font-weight: 300;
  color: rgba(47, 62, 104, 0.65);
}
.settings_component .user_status .user_status_inner.active_user {
  color: #25C873;
}
.settings_component .user_status .user_status_inner.error {
  color: #FF7791;
}
.settings_component .settings_grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-auto-flow: row dense;
}
.settings_component .settings_grid .content_box {
  position: relative;
  width: 100%;
}
.settings_component .settings_grid .grid_box {
  height: auto;
}
.settings_component .text_edit_item.mid_size_input {
  position: relative;
  width: 75%;
  max-width: 75%;
}
.settings_component .text_edit_item.hlaf_input {
  position: relative;
  width: 47%;
  max-width: 47%;
  display: inline-block;
  margin-left: 2%;
}
.settings_component .input_container_wrapper {
  position: relative;
  width: 95%;
  left: 2.5%;
}
.settings_component .in_box_switch {
  width: 60px;
}
.settings_component .outer_switch {
  width: 48px;
}
.settings_component .padding_line {
  position: relative;
  height: 20px;
}
.settings_component .cb_label_left_profile_pic {
  margin-left: 20px;
  font-size: 0.8rem;
  margin-bottom: 10px;
}
.settings_component .user_profile_pic {
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
}
.settings_component .user_profile_pic .profioe_pic_upload {
  position: relative;
  top: 10px;
}
.settings_component .user_profile_pic .profile_img {
  position: relative;
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  border-radius: 60px;
}
.settings_component .user_profile_pic .empty_img {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  background-color: #FFFFFF;
  background-image: url("../../img/profile_pic.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.settings_component .action_context_box_wrpaper {
  position: relative;
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-top: 10px;
  margin-bottom: -25px;
}
.settings_component .action_context_box_wrpaper .label {
  margin-top: 5px;
  margin-left: 10px;
}
.settings_component .small_contextbox {
  margin-bottom: 30px;
}
.settings_component .user_info {
  position: relative;
  left: 30px;
  top: 10px;
  height: 40px;
  width: 200px;
  display: flex;
  margin-bottom: 40px;
}
.settings_component .user_info .profile_img {
  position: relative;
  width: 45px;
  height: 45px;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  border-radius: 100px;
  max-height: 45px;
  max-width: 45px;
  -o-object-fit: cover;
     object-fit: cover;
}
.settings_component .user_info .empty_img {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  background-color: #FFFFFF;
  background-image: url("../../img/profile_pic.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.settings_component .user_info p {
  font-family: Raleway, sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative;
  top: 10px;
  left: 15px;
}
.settings_component .contact_btn {
  position: absolute;
  right: 10px;
  top: calc(100% - 30px);
}