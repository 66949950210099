@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.payment_calendar_wrapper {
  min-height: 350px;
}
.payment_calendar_wrapper .container_box_label {
  position: absolute;
  top: 15px;
  right: 20px;
}
.payment_calendar_wrapper .payment_calendar_container {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  width: 100%;
  height: 100%;
}
.payment_calendar_wrapper .payment_table_wrapper {
  position: relative;
  width: 100%;
  border: 1px solid  #ccc;
  height: 76%;
  margin-top: 5%;
}
.payment_calendar_wrapper .payment_table_wrapper .aux_table_wrapper {
  display: grid;
  grid-row-gap: 16px;
}
.payment_calendar_wrapper .payment_table_wrapper .ptw_line {
  position: relative;
  display: grid;
  width: 96%;
  left: 2%;
  border: 1px solid  #ccc;
  grid-template-columns: 1fr 1fr 1fr;
  height: 25px;
  line-height: 25px;
  vertical-align: center;
}
.payment_calendar_wrapper .payment_table_wrapper .ptw_line p {
  font-family: Roboto;
  font-weight: 300;
  font-size: 0.8rem;
  color: #2F3E68;
}
.payment_calendar_wrapper .payment_table_wrapper .ptw_line p.move_up {
  color: #25C873;
}
.payment_calendar_wrapper .payment_table_wrapper .ptw_line p.move_down {
  color: #FF7791;
}
.payment_calendar_wrapper .payment_table_wrapper .line_legend {
  border-bottom: 1px solid #2F3E68;
  height: 25px;
  margin-bottom: 5px;
  padding-left: 35px;
  padding-right: 35px;
}
.payment_calendar_wrapper .payment_table_wrapper .line_legend p {
  font-family: "Roboto Mono";
  font-weight: 600;
  font-size: 0.8rem;
  color: rgba(47, 62, 104, 0.65);
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_context_line {
  position: relative;
  margin-top: 10px;
  left: 2%;
  margin-bottom: 6px !important;
  font-size: 0.8rem;
  font-weight: 300;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box_wrapper {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-height: 82%;
  grid-row-gap: 16px;
  overflow: auto;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box {
  position: relative;
  left: 2%;
  width: 96%;
  height: 46px;
  box-shadow: 0 4px 20px rgba(69, 92, 90, 0.16);
  border-radius: 6px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 2px 35px;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box.past {
  border-left-width: 4px;
  border-style: solid;
  border-radius: 0px 6px 6px 0px;
  -o-border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
     border-image: linear-gradient(to bottom, #CBCAA5, #334D50) 1 100%;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box.expected {
  border-left-width: 4px;
  border-style: solid;
  border-color: rgba(47, 62, 104, 0.2);
  border-radius: 0px 6px 6px 0px;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box p {
  font-family: Roboto;
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 0px !important;
  color: #2F3E68;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box p.currency {
  font-size: 0.8rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 500;
  font-family: "Roboto Mono", monospace;
  letter-spacing: 0px;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box p.move_up {
  color: #25C873;
}
.payment_calendar_wrapper .payment_table_wrapper .pmt_line_box p.move_down {
  color: #FF7791;
}

@media only screen and (max-width: 1400px) {
  .payment_calendar_wrapper {
    min-height: 45vh;
    overflow-y: auto;
  }
}