@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
#switch_ui {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch_ui_label {
  cursor: pointer;
  width: 55px;
  height: 26px;
  background: #EEEEEE;
  display: block;
  border-radius: 100px;
  position: relative;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
  outline: 1px solid rgba(47, 62, 104, 0.2);
  box-sizing: border-box;
}
.switch_ui_label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 22px;
  height: 22px;
  background: #ffffff;
  border-radius: 30px;
  transition: 0.3s;
  box-shadow: 0 6px 20px rgba(51, 70, 68, 0.17);
}
.switch_ui_label:active:after {
  width: 55px;
}

.switch_ui:checked + .switch_ui_label {
  background: #2F3E68;
}
.switch_ui:checked + .switch_ui_label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.switch_container {
  position: relative;
  display: flex;
  width: auto;
}
.switch_container p {
  width: auto;
  display: block;
  position: relative;
  margin-right: 20px;
  font-weight: 300;
  color: #2F3E68;
}
.switch_container .switch_ui_label {
  top: -6px;
}