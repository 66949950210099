@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,800");
@import url("https://fonts.googleapis.com/css?family=Lato:100,200,300");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Source+Sans+Pro:200,300,400");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap");
.hlaf_inp_grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}

.register_container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FFFFFF;
  padding: 60px;
  height: auto;
}
.register_container .main_h1 {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 1.1rem;
  color: #2F3E68;
  font-weight: 400;
}
.register_container .sec_headline {
  position: relative;
  font-size: 0.9rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 300;
  margin-bottom: 30px;
  margin-left: 10px;
}
.register_container .reg_btns {
  position: relative;
  float: right;
  top: 30px;
  margin-bottom: 10px;
}
.register_container .back_btn {
  position: relative;
  left: -50px;
}

.forgoten_pass_container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FFFFFF;
  padding: 60px;
  height: auto;
  min-height: auto !important;
  padding-bottom: 50px;
}
.forgoten_pass_container .main_h1 {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 1.1rem;
  color: #2F3E68;
  font-weight: 400;
}
.forgoten_pass_container .sec_headline {
  position: relative;
  font-size: 0.9rem;
  color: rgba(47, 62, 104, 0.65);
  font-weight: 300;
  margin-bottom: 30px;
  margin-left: 10px;
}
.forgoten_pass_container .reg_btns {
  position: relative;
  float: right;
  top: 30px;
  margin-bottom: 10px;
  padding-bottom: 30px;
}
.forgoten_pass_container .back_btn {
  position: relative;
  left: -50px;
}

.register_form_container {
  position: fixed;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
}
.register_form_container .close_bnt {
  position: absolute;
  top: 40px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-image: url("../../img/icons/close_btn.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 110;
  cursor: pointer;
}

.reg_inner_conainer {
  width: 100%;
  padding: 0px 10%;
}

.register_link {
  position: absolute;
  bottom: 60px;
  left: 60px;
  line-height: 1.4em;
  max-width: 80%;
  overflow-wrap: break-word;
}

.register_link_subtitle {
  position: absolute;
  bottom: 30px;
  left: 60px;
  line-height: 1em;
  max-width: 80%;
  color: rgba(47, 62, 104, 0.4);
  font-weight: 300;
  overflow-wrap: break-word;
}

.register_link_legend {
  position: relative;
  top: 30px;
  left: 40px;
  line-height: 1.4em;
  max-width: 80%;
  overflow-wrap: break-word;
  color: #25C873 !important;
}

.res_pass_link {
  position: relative;
  margin-left: -60px;
}

.form_inside_reset {
  position: relative;
  padding: 0px 60px;
}